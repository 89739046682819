import React from "react";

const ConceptPropListItem = ({prop}) => {
	const propValue = () => {
		console.log('PROP_TYPE ',prop.value.__typename)
		console.log('PROP_VAL ', prop.value)
		switch (prop.value.__typename){
			case "StringValue": {
				return prop.value.value
			}
			case "LinkValue": {
				return prop.value.link
			}

		}
	}

	return <p key={prop.id}><b>{prop.propertyType.name}:</b> {propValue()}</p>
}

export default ConceptPropListItem