import React, {useEffect, useState} from "react";
import {analyticsBACK} from "../../ApiEndpoints";
import ConceptPropListItem from "./ConceptPropListItem";
import Skeleton from "react-loading-skeleton";

const ConceptDetail = ({id}) => {
	const [conceptProps, setConceptProps] = useState(null)
	useEffect(() => {
		analyticsBACK.post('/getConceptProps', {id: id})
			.then((resp) => {
				console.log(resp)
				const data = resp.data.data
				if(
					data.concept.id &&
					data.concept.name &&
					data.concept.paginationConceptProperty.total &&
					data.concept.paginationConceptProperty.listConceptProperty
				)
					setConceptProps({
						id: data.concept.id,
						name: data.concept.name,
						count: data.concept.paginationConceptProperty.total,
						props: data.concept.paginationConceptProperty.listConceptProperty
					})
			})
			.catch((resp) => console.log('getting concept props fail: ', resp))
	}, [id])

	console.log('PROPS: ', conceptProps)

	return <div>
		Concept {id} details
		{conceptProps
			? <div className="conceptInfo">
				<h1>{conceptProps.name} ({conceptProps.id})</h1>
				<p>Concept props ({conceptProps.count}):</p>
				{conceptProps.props.map((prop) => <ConceptPropListItem prop={prop} />)}
			</div>
			: <div className="conceptInfo">
				<h1><Skeleton/></h1>
				<Skeleton count={5} />
			</div>
		}


	</div>
}

export default ConceptDetail