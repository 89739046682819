import React from 'react';
import {Link} from "react-router-dom";
import {HandySvg} from 'handy-svg';
import {useSelector} from "react-redux";
import {TALISMAN_HOST} from "../../Consts";

import svg_hamburger from '../../ico/gamburger.svg';
import svg_arrow from '../../ico/arrow.svg';
import svg_settings from '../../ico/settings.svg';

const Header = () => {
	const user = useSelector((state) => state.user.value)

	document.addEventListener("click", (e) => {
		if(document.querySelector('.header') && !e.target.closest('.header')) {
			document.querySelector('.header-setting').classList.remove('active');
		}
	});

	const setting_toggle = (e) => {
		document.querySelector('.header-setting').classList.toggle('active');
	}

	const setSize = (size) => (e) => {
		let btn = e.target;

		btn.parentElement.querySelectorAll('.header-setting-itm__btn').forEach(function (itm){
			itm.classList.remove('active');
		})
		btn.classList.add('active');

		document.querySelector('body').style.fontSize = size;

	}

	const setTheme = (theme) => (e) => {
		document.querySelector('body').classList.remove("theme-dark");
		document.querySelector('body').classList.remove('theme-light');

		document.querySelector('body').classList.add(theme);

		let btn = e.target;

		btn.parentElement.querySelectorAll('.header-setting-itm__btn').forEach(function (itm){
			itm.classList.remove('active');
		})
		btn.classList.add('active');
	}

	return <div className="header-wrapper">
		<header className="header">
			<div className="content visible">
				<div className="header-content">
					<Link to="/">
						<img src="/img/logo.svg" alt=""/>
					</Link>

					<a href={TALISMAN_HOST} target="_blank" className="header-link with-arrow">
						<HandySvg src={svg_arrow} width={20} height={18}/>
						<span>Перейти в Талисман</span>
					</a>

					<button className="header-ico with-hover" onClick={setting_toggle}>
						<HandySvg src={svg_settings} width={24} height={24}/>
					</button>

					<Link to="/" className="header-link">
						<HandySvg src={svg_hamburger} width={13} height={10}/>
						<span>Запомненные объекты</span>
						<span className="pin">4</span>
					</Link>

					<Link to="/" className="header-link">
						<HandySvg src={svg_hamburger} width={13} height={10}/>
						<span>Мои ленты</span>
						<span className="pin active">23</span>
					</Link>

					<Link to='/auth' className='header-user'>{user.name}</Link>

					<div className="header-setting">
						<div className="header-setting-itm">
							<div className="header-setting-itm__title title">Размер шрифта</div>
							<div className="header-setting-itm__itms">
								<span className="header-setting-itm__btn setting-size-normal"
								      onClick={setSize("1em")}>А</span>
								<span className="header-setting-itm__btn setting-size-mid"
								      onClick={setSize("1.2em")}>А</span>
								<span className="header-setting-itm__btn setting-size-big"
								      onClick={setSize("1.4em")}>А</span>
							</div>
						</div>
						<div className="header-setting-itm">
							<div className="header-setting-itm__title title">Цветовая схема</div>
							<div className="header-setting-itm__itms">
								<span className="header-setting-itm__btn setting-theme-light"
								      onClick={setTheme('theme-light')}>Светлая</span>
								<span className="header-setting-itm__btn setting-theme-dark"
								      onClick={setTheme('theme-dark')}>Темная</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</header>
	</div>
}

export default Header;