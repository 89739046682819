import {createSlice} from "@reduxjs/toolkit";

export const conceptSlice = createSlice({
	name: 'conceptSlice',
	initialState: {
		shortList: []
	},
	reducers: {
		setConceptsShortList: (state, action) => {
			state.shortList = action.payload
		}
	}
})

export const { setConceptsShortList } = conceptSlice.actions

export default conceptSlice.reducer