import React from "react";
import AuthForm from "../../components/auth/AuthForm";

import authLogo from '../../img/auth-logo.png'
import authText from '../../img/auth-text.svg'

const AuthPage = () => {
	return <div className="auth-page">
		<div className="content visible">
			<div className="auth-page-content">
				<div className="auth-page-content__left">
					<img src={authLogo} alt="" className="auth-page-logo"/>
				</div>
				<div className="auth-page-content__right">
					<img src={authText} alt="" className="auth-page-text"/>

					<div className="title">Система анализа данных представляет собой самостоятельную систему для
						моделирования и построения социальных связей исследуемых объектов – конкретных публичных
						акторов
						международных отношений (государств, персон или организаций).
					</div>

					<p className="small light auth-page-desc">Цель системы – обеспечение удобной работы
						пользователей в части выявления
						явных или скрытых социальных связей объектов исследования – участников международных
						отношений. Для авторизации необходимо использовать логин и пароль от сервиса Талисман. </p>

					<AuthForm />
				</div>
			</div>
		</div>
	</div>
}

export default AuthPage