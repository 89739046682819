import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {ApolloClient, InMemoryCache, ApolloProvider} from "@apollo/client";

import './styles/style.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import {Provider} from "react-redux";
import Store from './Store'

window.React1 = require('react');

const client = new ApolloClient({
    uri: 'localhost',
    cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
      <Provider store={Store}>
          <ApolloProvider client={client}>
              <BrowserRouter>
                    <App/>
              </BrowserRouter>
          </ApolloProvider>
      </Provider>
  </>
);