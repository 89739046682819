import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/userSlice";
import conceptSlice from "./reducers/conceptSlice";
import statisticSlice from "./reducers/statisticSlice";

export default configureStore({
	reducer: {
		user: userSlice,
		concepts: conceptSlice,
		statistic: statisticSlice
	},
})