import React, {useEffect, useState} from "react"
import Header from "./Header";
import Footer from "./Footer";
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setAuthData, setUserData} from "../../reducers/userSlice";
import LoaderPage from "../laders/LoaderPage";
import AuthPage from "../../routes/AuthPage/AuthPage";

const MainTheme = ({children}) => {

	const userAuth = useSelector((state) => state.user.auth)
	const [authCheck, setAuthCheck] = useState(userAuth)
	const dispatch = useDispatch()

	useEffect(() => { //refreshing token if user authorized
		console.log('REFRESHING_RECREATED')
		console.log(userAuth)
		const check = setInterval(() => {
			console.log('REFRESHING_TOKEN_TRIGGERED')
			console.log(userAuth)
			if (userAuth){
				console.log('REFRESHING_TOKEN_SEND')
				analyticsBACK.get('/getCurrentUser')
					.then((resp) => {
						console.log('REFRESHING_TOKEN_SUCCESS')
					})
					.catch((resp) => {
						console.error('REFRESHING_TOKEN_FAIL')
						setUserData(false)
						dispatch(setAuthData(null))
					})
			}
		}, 200000)

		return () => clearInterval(check);
	}, [userAuth])

	useEffect(() => { //auth check on page load if not checked
		if(!authCheck){
			analyticsBACK.get('/expired')
				.then((resp) => {
					analyticsBACK.get('/getCurrentUser')
						.then((resp) => {
							if(resp && resp.data && resp.data.data && resp.data.data.currentUser){
								dispatch(setAuthData(true))
								dispatch(setUserData(resp.data.data.currentUser))
							}
							setAuthCheck(true)
						})
				})
				.catch((resp) => {
					console.error("Auth expired", resp);
					dispatch(setAuthData(null))
					dispatch(setUserData(null))
					if(resp && resp.request && resp.request.status === 500){
						console.error('server vas gone away')
						setAuthCheck(false)
						dispatch(setAuthData(false))
					}else{
						setAuthCheck(true)
					}
				})
		}
	}, [])

	if(authCheck) {
		if(userAuth){
			return <div className="wrapper">
				<Header />
				<main className='main-content content visible'>
					{children}
				</main>
				<Footer />
			</div>
		}else{
			return <AuthPage />
		}
	}else{
		return <LoaderPage />
	}
}

export default MainTheme