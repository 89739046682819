import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setUserData, setAuthData} from "../../reducers/userSlice";
import FormErrors from "../ui/FormErrors";
import {HandySvg} from "handy-svg";

import svg_arrow from '../../ico/arrow.svg';

const AuthForm = () => {
	const [errors, setErrors] = useState([])
	const [value, setValue] = useState({login: '', password: ''})
	const [formBusy, setFormBusy] = useState(false)
	const dispatch = useDispatch()

	const handleAuth = (e) => {
		e.preventDefault()
		setErrors([])
		if(!value.password){
			setErrors(['Введите пароль'])
			return false
		}
		if(!value.login){
			setErrors(['Введите логин'])
			return false
		}

		setFormBusy(true)

		var formData = new FormData();
		formData.append('username', value.login);
		formData.append('password', value.password);
		analyticsBACK.post('/oauth/login', formData)
			.then((resp) => {
				if (resp.status === 200) {
					analyticsBACK.get('/getCurrentUser')
						.then((resp) => {
							if(resp && resp.data && resp.data.data && resp.data.data.currentUser){
								setFormBusy(false);
								dispatch(setAuthData(true))
								dispatch(setUserData(resp.data.data.currentUser))
							}
						})
				} else
					setErrors(['Произошла непредвиденная ошибка, повторите попытку позже'])
			})
			.catch((resp) => {
				setFormBusy(false)
				setErrors(['Произошла непредвиденная ошибка, повторите попытку позже'])
				console.error("Auth Fail")
			})
	}

	const customValidity = (message, event) => {
		event.target.setCustomValidity("")
		if(!event.target.validity.valid)
			event.target.setCustomValidity(message)
	}
	const resetValidityMessage = (event) => {
		event.target.setCustomValidity("")
	}

	return <>
		<form action="" aria-disabled={formBusy} className="nice-form js-auth-form" onSubmit={(e) => handleAuth(e)}>
			<div className="cols cols-2 p10" >

				<div className="col">
					<label className="input-wrap">
						<input
							name='login'
							type='text'
							placeholder=" "
							value={value.login}
							onChange={(e) => setValue({...value, login: e.target.value})}
							onInput={(e) => resetValidityMessage(e)}
							onInvalid={(e) => customValidity('Введите логин', e)}
							required={true}
						/>
						<span className="input-wrap__label">Логин</span>
					</label>
				</div>

				<div className="col">
					<label className="input-wrap">
						<input
							name='password'
							type='password'
							placeholder=" "
							value={value.password}
							onChange={(e) => setValue({...value, password: e.target.value})}
							onInput={(e) => resetValidityMessage(e)}
							onInvalid={(e) => customValidity('Заполните пароль', e)}
							required={true}
						/>
						<span className="input-wrap__label">Пароль</span>
					</label>
				</div>
			</div>

			<div className="form-message"></div>

			<button type='submit' className="btn big">
				Авторизация
				<HandySvg src={svg_arrow} width={20} height={18}/>
			</button>
			<FormErrors errors={errors} />
		</form>
	</>
}


export default AuthForm