import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: 'userState',
	initialState: {
		value: null,
		auth: false,
	},
	reducers: {
		setUserData: (state, action) => {
			state.value = action.payload
		},
		setAuthData: (state, action) => {
			state.auth = action.payload
		}
	}
})

export const { setUserData, setAuthData } = userSlice.actions

export default userSlice.reducer