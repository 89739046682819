import React, {useEffect, useState, Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import LoaderPage from "./components/laders/LoaderPage";
import Main from "./routes/Main/Main";
import AuthPage from "./routes/AuthPage/AuthPage";
import ConceptPage from "./routes/Concepts/ConceptPage";

// const Main = React.lazy(() => import("./routes/Main/Main"))
// const AuthPage = React.lazy(() => import("./routes/AuthPage/AuthPage"))
// const ConceptPage = React.lazy(() => import("./routes/Concepts/ConceptPage"))

const App = () => {

	return <>
		<Routes>
			<Route path="/" element={<Suspense fallback={<LoaderPage />}><Main /></Suspense>} />
			<Route path="/auth" element={<Suspense fallback={<LoaderPage />}> <AuthPage /> </Suspense>} />
			<Route path="/concept/:id" element={<Suspense fallback={<LoaderPage />}><ConceptPage /></Suspense>} />
		</Routes>
	</>
}

export default App