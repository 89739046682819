import React from "react";
import MainTheme from "../../components/theme/MainTheme";
// import MainAnalytics from "../../components/mainPage/MainAnalytics";
import {Link} from "react-router-dom";

const MainAnalytics = React.lazy(() => import("../../components/mainPage/MainAnalytics"))

const Main = () => {
	return <MainTheme>
		<MainAnalytics />

		<div className="main-analytic">
			<div className="ta-c title c-main-dark">Сформировать новую аналитическую работу:</div>

			<div className="cols cols-3 p10">

				<div className="col">
					<Link to="/query" className="btn">Сформировать досье</Link>
					<div className="main-analytic-desc">
						Описание того что пользователь может получить в данном типе аналитической работы. Что
						это получение статичной и динамичной информации на конкретный концепт.
					</div>
				</div>


				<div className="col">
					<Link to="/query" className="btn">Построить связи обьектов</Link>
					<div className="main-analytic-desc">
						Описание того что пользователь может получить в данном типе аналитической работы. Что
						это получение статичной и динамичной информации на конкретный концепт.
					</div>
				</div>


				<div className="col">
					<Link to="/query" className="btn">Сформировать ленту</Link>
					<div className="main-analytic-desc">
						Описание того что пользователь может получить в данном типе аналитической работы. Что
						это получение статичной и динамичной информации на конкретный концепт.
					</div>
				</div>

			</div>
		</div>
	</MainTheme>
}

export default Main