import axios from "axios";
import {BACK_HOST, FRONT_HOST} from "./Consts";
import {ApolloClient, InMemoryCache} from "@apollo/client";

export const analyticsBACK = axios.create({
	baseURL: BACK_HOST+"/api",
	timeout: 120000,
	withCredentials: true,
	headers: {
		"Accept": "application/json, text/plain, */*"
	}
})


export const APIClient = new ApolloClient({
	uri: BACK_HOST+'/api/graphQL',
	cache: new InMemoryCache(),
	origin: FRONT_HOST,
	credentials: 'include'
})