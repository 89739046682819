import React from "react";
import {useParams} from "react-router-dom";
import ConceptDetail from "../../components/concepts/ConceptDetail";
import MainTheme from "../../components/theme/MainTheme";

const ConceptPage = () => {
	const {id} = useParams()
	return <MainTheme>
		<div>
			Concept Page {id}
			<ConceptDetail id={id} />
		</div>
	</MainTheme>
}

export default ConceptPage